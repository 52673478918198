@import '../mixins';

.mainLoaderContainer {
  height: 100%;
  @include center;
  width: 100%;
  background-color: rgba($color: #fff, $alpha: 0.8);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999900000 !important;
  pointer-events: none;
  &_logoLoader {
    animation: pulseanim 2s ease-in-out infinite;

    @keyframes pulseanim {
      0% {
        transform: scale(1.5);
        opacity: 1;
      }
      50% {
        transform: scale(1);
        opacity: 0.9;
        filter: invert(50%);
      }
      100% {
        transform: scale(1.5);
        opacity: 1;
      }
    }
  }
}
.mainLoaderContainerHidden {
  display: none;
}
