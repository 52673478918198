@import './mixins';
:root {
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono',
    'Roboto Mono', 'Oxygen Mono', 'Ubuntu Monospace', 'Source Code Pro',
    'Fira Mono', 'Droid Sans Mono', 'Courier New', monospace;

  --primary: #d12d34;
  --primary-medium: #ffa2a6;
  --primary-light: #ffeeef;
  --blue-main: #3043f0;
  --blue-medium: #cad0ff;
  --white: #fefeff;
  --main-text: #2e2c41;

  --sec-text: #666478;

  --main-bg: #fff;
  --sec-bg: #f8f8f9;
  --dark-bg: #39393d;
  --card-green: #e0ffd8;
  --card-main: #fefeff;
  --card-sec: #fbfbff;
  --stock-green: #3cb288;
  --light-green: rgba(85, 213, 167, 0.1);
  --light-red: rgba(209, 45, 52, 0.2);
  --border-main: #ebebeb;

  --secondary-accent: #f0f1f4;
}

// @media (prefers-color-scheme: dark) {
//   :root {
//     --primary: #d12d34;
//     --primary-light: #ffeeef;

//     --main-text: #f8f8f9;
//     --sec-text: #a3a3ad;
//     --primary-text: #fff;
// --dark-bg: #39393d;
//     --main-bg: #f0f1f4;
//     --sec-bg: rgba(243, 244, 255, 0.1);

//     --card-green: #e0ffd8;
//     --card-main: #fefeff;
//     --stock-green: #3cb288;
// --light-green: rgba(85, 213, 167, 0.1);
//     --active-green: #23946b;
//     --border-main: #33333a;

//     --secondary-accent: #f0f1f4;
//   }
// }

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  // outline: 1px solid red;
}

div {
  box-sizing: border-box;
}
html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
}

body {
  color: var(--main-text);
  background: var(--main-bg);
}

a {
  color: inherit;
  text-decoration: none;
}

// @media (prefers-color-scheme: dark) {
//   html {
//     color-scheme: dark;
//   }
// }

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  animation-fill-mode: both;
  animation: bblFadInOut 1.8s infinite ease-in-out;
  @include mobile {
    width: 10px;
    height: 10px;
  }
}

.loader {
  color: rgba($color: #000000, $alpha: 0.2);
  font-size: 7px;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}
.loader:before {
  left: -3.5em;
  animation-delay: -0.32s;
}
.loader:after {
  left: 3.5em;
}

.mloader,
.mloader:before,
.mloader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  animation-fill-mode: both;
  animation: bblFadInOut 1.8s infinite ease-in-out;
  @include mobile {
    width: 10px;
    height: 10px;
  }
}
.swal2-container {
  z-index: 999999999999 !important;
}
.mloader {
  color: rgba($color: #d12d34, $alpha: 0.6);
  font-size: 7px;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  animation-delay: -0.16s;
}
.mloader:before,
.mloader:after {
  content: '';
  position: absolute;
  top: 0;
}
.mloader:before {
  left: -3.5em;
  animation-delay: -0.32s;
}
.mloader:after {
  left: 3.5em;
}

@keyframes bblFadInOut {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

.loaderMain {
  @include center;
}

.customSwiperButton {
  cursor: pointer;

  width: 40px;
  height: 40px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  svg {
    z-index: 1;
  }
  @include mobile {
    width: 32px;
    height: 32px;
  }
}
.customSwiperButton-left {
  position: absolute;
  bottom: 0;
  right: 72px;
}
.customSwiperButton-right {
  position: absolute;
  bottom: 0;
  right: 0;
}
.landingSwiperBtn-left {
  position: absolute;
  left: -70px;
  top: 50%;
  transform: translateY(-50%);
}
.landingSwiperBtn-right {
  position: absolute;
  right: -70px;
  top: 50%;
  transform: translateY(-50%);
}
.customSwiperButtondisabled {
  cursor: not-allowed;
}
.swiperRipple {
  width: 5px;
  height: 5px;
  position: absolute;
  z-index: 0;
  background: var(--primary-light);
  display: block;
  content: '';
  border-radius: 50%;
  opacity: 1;
  animation: 0.3s ease-in-out ripple-effect;
}

.swiper {
  @include mobile {
    overflow: visible !important;
  }
}
.swiper-slide {
  width: auto;
}
@keyframes ripple-effect {
  0% {
    transform: scale(0.5);
    opacity: 1;
  }
  50% {
    transform: scale(10);
    opacity: 0.5;
  }
  100% {
    transform: scale(35);
    opacity: 0.1;
  }
}

.swiperr-bot {
  padding: 10px 0 !important;
  z-index: 5 !important;
}
swiper-container {
  z-index: 10;
}

.vertical-news {
  height: 558px !important;
  @include mobile {
    height: 265px !important;
  }
}

.terminal-bread-crumbs {
  padding: 30px 60px;
  @include mobile {
    padding: 10px 16px;
  }
}

.terminal-bread-crumbs .bread-crumb-mobile {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #d12d34;
  padding-bottom: 10px;
  h2 {
    flex-grow: 1;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: var(--main-text);
  }
  svg {
    cursor: pointer;
  }
}

.bread-crumb-mobile .crumb-back-icon {
  @include center;
}
.bread-crumb-mobile--transparent .crumb-back-icon {
  @include center;
}
.terminal-bread-crumbs .bread-crumb-mobile--transparent {
  display: flex;
  align-items: center;

  h2 {
    flex-grow: 1;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: var(--card-main);
  }
  svg {
    cursor: pointer;
    color: var(--card-main);
  }
}
.news-socials {
  display: flex;
  gap: 20px;
  align-items: center;
}

.highcharts-credits {
  display: none;
}

.highcharts-customTooltip {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  width: max-content !important;
  height: max-content !important;
  span {
    display: flex;

    flex-direction: column;
    font-size: 12px !important;
    gap: 4px;
    font-family: Montserrat;
    color: var(--sec-text);
    font-weight: 500;
    span {
      display: flex;
      flex-direction: row;
      b {
        font-weight: 600;
        color: $--main-text;
      }
    }
  }
}

.table-pagination-bt {
  display: flex;
  width: 100%;
  padding: 50px 30px 10px;
  justify-content: space-between;
  @include mobile {
    padding: 20px 10px 10px;
  }
  aside {
    display: flex;
    gap: 5px;
    align-items: center;
    span {
      @include mobile {
        font-size: 14px;
      }
    }
  }
}

.dot-spinner {
  --uib-size: 2.8rem;
  --uib-speed: 0.9s;
  --uib-color: #183153;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: var(--uib-size);
  width: var(--uib-size);
}

.dot-spinner__dot {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
}

.dot-spinner__dot::before {
  content: '';
  height: 20%;
  width: 20%;
  border-radius: 50%;
  background-color: var(--uib-color);
  transform: scale(0);
  opacity: 0.5;
  animation: pulse0112 calc(var(--uib-speed) * 1.111) ease-in-out infinite;
  box-shadow: 0 0 20px rgba(18, 31, 53, 0.3);
}

.dot-spinner__dot:nth-child(2) {
  transform: rotate(45deg);
}

.dot-spinner__dot:nth-child(2)::before {
  animation-delay: calc(var(--uib-speed) * -0.875);
}

.dot-spinner__dot:nth-child(3) {
  transform: rotate(90deg);
}

.dot-spinner__dot:nth-child(3)::before {
  animation-delay: calc(var(--uib-speed) * -0.75);
}

.dot-spinner__dot:nth-child(4) {
  transform: rotate(135deg);
}

.dot-spinner__dot:nth-child(4)::before {
  animation-delay: calc(var(--uib-speed) * -0.625);
}

.dot-spinner__dot:nth-child(5) {
  transform: rotate(180deg);
}

.dot-spinner__dot:nth-child(5)::before {
  animation-delay: calc(var(--uib-speed) * -0.5);
}

.dot-spinner__dot:nth-child(6) {
  transform: rotate(225deg);
}

.dot-spinner__dot:nth-child(6)::before {
  animation-delay: calc(var(--uib-speed) * -0.375);
}

.dot-spinner__dot:nth-child(7) {
  transform: rotate(270deg);
}

.dot-spinner__dot:nth-child(7)::before {
  animation-delay: calc(var(--uib-speed) * -0.25);
}

.dot-spinner__dot:nth-child(8) {
  transform: rotate(315deg);
}

.dot-spinner__dot:nth-child(8)::before {
  animation-delay: calc(var(--uib-speed) * -0.125);
}

@keyframes pulse0112 {
  0%,
  100% {
    transform: scale(0);
    opacity: 0.5;
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }
}

//for calender buttons
@media (min-width: 200px) and (max-width: 767px) {
  .rdrDateRangePickerWrapper {
    flex-direction: column-reverse;
  }
}
@media (min-width: 200px) and (max-width: 767px) {
  .rdrInputRanges {
    display: none;
  }
}
@media (min-width: 200px) and (max-width: 767px) {
  .rdrDefinedRangesWrapper {
    width: 100% !important;
  }
}
@media (min-width: 200px) and (max-width: 767px) {
  .rdrStaticRanges {
    flex-direction: row !important;
    overflow-x: auto;
  }
}
@media (min-width: 200px) and (max-width: 767px) {
  .rdrMonth {
    width: 100% !important;
  }
}

.close-button {
  display: flex;
  height: 30px;
  width: 100px;
  align-items: center;

  margin-left: calc(100% - 100px);
  align-self: center;
  justify-content: center;
  background-color: transparent;
  cursor: pointer;
  font-weight: 700;
  color: red;
  border-radius: 5px;
  font-size: 14px;
  margin-top: 5px;
  position: absolute;
  top: 2px;
  right: 2px;
  z-index: 999999;
}
.close-button > svg {
  color: red;
}

.MuiBottomNavigationAction-label {
  font-size: 0.75rem !important;
}

.disable-scroll {
  overflow: hidden !important;
  div {
    overflow: hidden !important;
  }
  html,
  body {
    overflow: hidden !important;
  }
}

.mainLayoutContainer {
  @include mainlayout;
}

.indexPage {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-bottom: 40px;
  @include mobile {
    gap: 20px;
    padding-bottom: 20px;
  }
}

.center-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

// .scroll-container {
//   overflow-y: auto;
//   max-height: 100vh;
//   padding: 20px;
// }

.agent-container-main {
  padding: 20px;
  padding-top: 0px;
  background-color: #1c1c1c;
  min-height: 100vh;
}

.agent-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.agent-head {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.agent-pic {
  width: 107px;
  height: 107px;
  border-radius: 50%;
  margin-bottom: 6px;
  object-fit: cover;
  object-position: top center;
}
.agent-approved {
  position: absolute;
  right: 0px;
  bottom: 4px;
  z-index: 2;
}
.agent-name {
  font-size: 16px;
  color: #f2f2f2;
}

.agent-company {
  font-size: 14px;
  color: #969696;
}

.agent-divider {
  height: 1px;
  background-color: #ccc;
}

.details-divider {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.agent-details {
  background-color: #2c2c2e;
  padding: 12px 20px;
  border-radius: 12px;
  text-align: center;
}

.area-head {
  font-size: 12px;
  color: #f2f2f2;
}

.area-name {
  font-size: 12px;
  font-weight: bold;
  color: #fff;
}

.listings-container {
  margin-top: 20px;
  width: 100%;
}

.listing-head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.listing-head-text {
  font-size: 16px;
  color: #f2f2f2;
}

.loading {
  text-align: center;
}

.listings-scroll {
  width: max-content;
  display: flex;
  gap: 20px;
  overflow-x: auto;
}

.loading-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.load-more {
  text-align: center;
  cursor: pointer;
  margin-top: 20px;
}

.headerBack {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  height: 60px;
  background-color: #1c1c1c;
}
.headerBack span {
  color: #f2f2f2;
}
.filter-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
  padding: 20px 0;
  transition: all 0.3s ease;
}

.date-picker-container {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
}
.date-picker-container .divs {
  width: max-content;
  display: flex;
  gap: 10px;
  align-items: center;
}
.divs label {
  color: #f2f2f2;
  font-size: 14px;
}

.price-picker-container {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.submit-button {
  background-color: #ff6b6b;
  padding: 10px;
  border: none;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  cursor: pointer;
}

.filter-container input {
  padding: 6px 4px;
  background-color: #2c2c2e;
  font-size: 14px;
  border: none;
  border-radius: 6px;
  color: #f2f2f2;
  outline: none;
}
.filter-container select {
  padding: 12px 12px;
  background-color: #2c2c2e;
  font-size: 14px;
  border: none;
  border-radius: 6px;
  color: #f2f2f2;
  outline: none;
  width: 100%;
}

.s-filter-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
  padding: 20px 0;
  transition: all 0.3s ease;
}
.s-price-picker-container {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.s-filter-container input {
  padding: 6px 4px;
  background-color: #2c2c2e;
  font-size: 14px;
  border: none;
  border-radius: 18px;
  color: #f2f2f2;
  outline: none;
}
.s-filter-container select {
  padding: 12px 12px;
  background-color: #2c2c2e;
  font-size: 14px;
  border: none;
  border-radius: 18px;
  color: #f2f2f2;
  outline: none;
  width: 100%;
}

.s-submit-button {
  background-color: #ff565d;
  padding: 10px;
  border: none;
  border-radius: 5px;
  color: #f2f2f2;
  font-weight: bold;
  cursor: pointer;
  width: max-content;
  align-self: center;
  border-radius: 32px;
  padding: 12px 18px;
}

.row-button-selector {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  background-color: #1c1c1e;
  border-radius: 32px;
  padding: 2px;
  border: 1px solid #2b2a2f;
  transition: all 0.3s ease;
}
.row-button-selector button {
  outline: none;
  height: 40px;
  width: 100%;
  flex-grow: 1;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  outline: none;
  border-radius: 32px;
  border: none;
  background: none;
  transition: all 0.3s ease;
}

.swal-delete-button-confirm {
  border: none !important;
  outline: none !important;
}

.swal2-title {
  font-size: 18px;
}

#project-area-input {
  min-width: 150px !important;
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.pulse-animation {
  animation: pulse 1s infinite ease-in-out;
}

.golden-gradient {
  background: linear-gradient(
    to right,
    #bf953f,
    #fcf6ba,
    #b38728,
    #fbf5b7,
    #aa771c
  );
  background-size: 400% 400%;
  animation: gradientAnimation 10s ease infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
