@import '../mixins';

.mainContainer {
  @include mainlayout;
}

.terminalButton {
  min-width: 100px;
  width: max-content;
  padding: 0 48px;
  height: 50px;
  border: none;
  border-radius: 48px;
  @include center;
  gap: 10px;
  cursor: pointer;
  background-color: $--primary;
  color: $--card-main;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  z-index: 2;
}
.terminalpassiveButton {
  min-width: 100px;
  width: max-content;
  padding: 0 48px;
  height: 50px;
  border: none;
  border-radius: 48px;
  @include center;
  gap: 10px;
  cursor: pointer;
  background-color: $--card-main;
  color: $--primary;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  z-index: 2;
}
