@import '../mixins';

.editListingcontainer {
}
.enquiryContainer {
  display: flex;
  flex-direction: column;

  align-items: center;
  color: #fff;
  gap: 10px;
  height: auto;
  overflow: auto;
  gap: 20px;

  &_edits {
    display: flex;
    width: 100%;
    color: $--main-text !important;
    flex-direction: column;
    gap: 5px;
    label {
      color: $--main-text !important;
    }
    span {
      color: $--main-text !important;
    }
    &_image {
      display: flex;
      flex-wrap: wrap;
    }
    input {
      width: 100%;
      min-width: 300px;
      height: 40px;
      outline: none;
      margin-top: 12px;
      padding: 2px 12px;
      border-radius: 8px;
    }
  }
  &_phoneinput {
    color: #000;
    background-color: #fff;
    margin-top: 10px;
    width: 100%;
  }
  &_wish {
    color: $--main-text;
  }
  &_options {
    width: 100%;
    color: $--main-text;
    height: 250px;
  }
  &_main {
    background-color: #fff !important;
    color: #000 !important;
    border: none !important;

    border-right: 1px solid gray !important;
    outline: none;
    &.open {
      background: #fff !important;
    }
  }
  &_inputClass {
    width: 100% !important;
    background-color: #fff !important;
    background: #fff !important;
    color: #000 !important;
    outline: none;
    height: 40px !important;
    font-size: 1rem !important;
    // padding: 5px 10px !important;
    border: 1px solid #000 !important;
    &::placeholder {
      color: gray;
    }
  }
  &_dropmain {
    background-color: #fff !important;
    color: #000 !important;
    border: none !important;
    // border-right: 1px solid gray !important;
    outline: none;
    width: 100%;
    background: #fff !important;
  }
  &_tab {
    &_stab {
      color: #d12d34 !important;
    }
    &_tab {
      color: #000 !important;
    }
  }
  &_reviews {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 5px;
    &_suspended {
      color: #d12d34;
    }
    label {
      color: $--main-text;
      font-size: 14px;
      margin-top: 10px;
    }
    input {
      border-radius: 48px;
      background: $--card-main;
      box-shadow: -4px 24px 38px 0px rgba(161, 173, 205, 0.12);
      color: #000;
      border: none;
      outline: none;
      height: 40px;
      font-size: 16px;
      color: $--main-text;
      font-size: 1rem;
      &::placeholder {
        font-size: 16px;
        color: $--sec-text;
      }
      padding: 5px 10px;
    }
    textarea {
      border-radius: 8px;
      background: $--card-main;
      box-shadow: -4px 24px 38px 0px rgba(161, 173, 205, 0.12);
      border: none;
      color: $--main-text;
      font-size: 16px;
      padding: 10px;
      outline: none;
      font-size: 1rem;
    }
  }
  &_buttoncancel {
    color: #d12d34 !important;
    &:first-of-type {
      color: gray !important;
    }
  }
}
.mainContainer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  z-index: 9999999;
  height: 100vh;
  @include center;
  background-color: rgba($color: #39393d, $alpha: 0.7);
  padding: 20px;
  &_mainDialog {
    width: 432px;
    min-height: 380px;
    padding: 30px;
    box-shadow: -4px 24px 38px rgba(161, 173, 205, 0.12);
    border-radius: 24px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 32px;
    &_icon {
      width: 80px;
      height: 80px;
      @include center;

      border-radius: 50%;
      svg {
        animation: smallbig 0.8s;
        @keyframes smallbig {
          0% {
            transform: scale(0.8);
          }
          25% {
            transform: scale(1);
          }
          50% {
            transform: scale(1.1);
          }
          100% {
            transform: scale(1);
          }
        }
      }
    }
    &_texts {
      display: flex;
      flex-direction: column;
      gap: 12px;
      align-items: center;
      h1 {
        font-weight: 700;
        font-size: 20px;
        line-height: 32px;
        color: $--main-text;
        text-align: center;
      }
      p {
        font-weight: 400;
        text-align: center;
        font-size: 16px;
        line-height: 24px;
        color: $--sec-text;
        span {
          color: $--primary;
        }
      }
    }
    &_back {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      button {
        border: none !important;
        font-weight: 600;
      }
    }
    &_button {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 30px;
    }
  }
}

.DmainContainer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  z-index: 999999999999 !important;
  height: 100vh;
  @include center;
  background-color: rgba($color: #1c1c1e, $alpha: 0.7);
  &_mainDialog {
    width: 90%;
    min-height: 380px;
    padding: 40px;

    border-radius: 24px;
    background-color: #2c2c2e;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 32px;
    &_icon {
      width: 80px;
      height: 80px;
      @include center;

      border-radius: 50%;
      svg {
        animation: smallbig 0.8s;
        @keyframes smallbig {
          0% {
            transform: scale(0.8);
          }
          25% {
            transform: scale(1);
          }
          50% {
            transform: scale(1.1);
          }
          100% {
            transform: scale(1);
          }
        }
      }
    }
    &_texts {
      display: flex;
      flex-direction: column;
      gap: 12px;
      align-items: center;
      h1 {
        font-weight: 700;
        font-size: 20px;
        line-height: 32px;
        color: #f2f2f2;
        text-align: center;
      }
      p {
        font-weight: 400;
        text-align: center;
        font-size: 16px;
        line-height: 24px;
        color: #989898;
        span {
          color: $--primary;
        }
      }
    }
    &_button {
      width: 100%;
    }
  }
}
