@import '../../mixins';

.mainHeaderContainer {
  z-index: 1000;
  @include mw-margin;
  width: 100%;

  padding: 16px 50px;
  display: flex;

  align-items: center;
  @include mobile {
    padding: 5px 20px;
    gap: 20px;
    height: 50px;
    display: flex;
    // justify-content: space-between;
  }
  &_mobileMenu {
    display: none;
    @include mobile {
      display: flex;
      cursor: pointer;
    }
    img {
      // filter: brightness(0.5) invert(1);
    }
  }
  &_desktopMenu {
    margin-left: auto;
    height: 68px;
    @include mobile {
      display: none;
    }
  }
  &_auth {
    width: max-content;
    display: flex;
    gap: 28px;
    padding: 0 16px 0 32px;
    padding-right: 0;
    margin-left: auto;
    @include mobile {
      padding: 0;
      // margin-left: auto;
    }
    &_box {
      display: flex;
      gap: 28px;
      padding: 0 16px 0 32px;
      border: 1px solid #ebebeb;
      border-radius: 50px;
      overflow: hidden;
      height: 68px;
      display: flex;
      align-items: center;
      @include mobile {
        padding: 0;
        border: none;
      }
    }
    button {
      cursor: pointer;
    }
    @include mobile {
      padding: 0;
    }
    &_register {
      padding: 0;
      outline: 0;
      border: 0;
      color: var(--main-text);
      background-color: transparent;
      // font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      @include mobile {
        display: none;
      }
    }
    &_login {
      padding: 8px 24px;
      color: var(--primary);
      background-color: var(--primary-light);
      outline: none;
      height: 36px;
      width: 94px;
      border: none;
      border-radius: 20px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
    }
  }
  &_logo {
    width: 150px;
    height: 34px;
    @include mobile {
      width: 125px;
      height: 34px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      cursor: pointer;
      @include mobile {
        width: auto;
        height: 34px;
      }
    }
  }
}
