@import '../../mixins';

.mainHomecontainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.messageContainer {
  margin-top: 10px;
  padding: 0 60px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 42px;
  @include mobile {
    flex-direction: column;
    align-items: baseline;
    padding: 0 20px;
    gap: 0;
  }

  &_left {
    width: 38%;
    display: flex;
    flex-direction: column;
    gap: 40px;

    @include mobile {
      width: 100%;
      gap: 20px;
    }
    h1 {
      font-style: normal;
      font-weight: 600;
      font-size: 62px;
      line-height: 70px;
      color: $--main-text;
      @include mobile {
        font-size: 36px;
        line-height: 36px;
      }
    }
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: $--sec-text;
      @include mobile {
        color: $--main-text;
      }
    }
  }
  &_right {
    width: 58%;
    position: relative;
    @include mobile {
      width: 100%;
      margin-top: auto;
    }
    img {
      width: 100%;
      height: 700px;
      @include mobile {
        height: 366px;
      }
      &:last-of-type {
        width: auto;
        height: 150px;
        position: absolute;
        bottom: 10px;
        right: 10px;
        cursor: pointer;
        @include mobile {
          height: 120px;
          right: 0;
          bottom: 50px;
        }
      }
    }
  }
}
