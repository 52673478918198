@import '../../mixins';

.container {
  background: linear-gradient(
    168.89deg,
    rgba(11, 32, 40, 0.15) -195.48%,
    #0b2028 200.29%
  );
}
.mainFooterContainer {
  @include mw-margin;
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 28px 100px;
  gap: 35px;
  // background-color: var(--dark-bg);

  @include mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 18px 16px;
    gap: 25px;
    // margin-bottom: 60px;
  }
  h1 {
    color: var(--card-main);
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    @include mobile {
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
    }
  }
  &_top {
    display: flex;
    align-items: center;
    gap: 20px;
    width: 100%;
    @include mobile {
      gap: 1px;
      flex-direction: column;
      align-items: flex-start;
    }
    p {
      color: #fff;
      font-size: 16px;
      font-weight: 500;
      @include mobile {
        font-size: 12px;
      }
    }
    span {
      @include mobile {
        font-size: 12px !important;
      }
    }
    &_divider {
      height: 12px;
      border-left: 1px solid #fff;
      @include mobile {
        display: none;
      }
    }
    a {
      text-decoration: none !important;
      @include mobile {
        font-size: 12px !important;
      }
    }
  }
  &_mid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 25px;

    @include mobile {
      display: flex;
      flex-direction: column;
      gap: 10px;

      overflow: auto;
    }
    button {
      margin-left: auto;
      background-color: transparent;
      border: none;
      outline: none;
      border: 1px solid #fff;
      border-radius: 10px;
      padding: 10px;
      cursor: pointer;
    }
    &_item {
      cursor: pointer;
      display: flex;
      width: 330px;
      align-items: center;
      // flex-grow: 1;
      gap: 10px;
      @include mobile {
        height: 45px;
      }
      &_avatar {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        overflow: hidden;
        background-color: $--sec-text;
        @include center;
        img {
          @include center;
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
          object-position: center center;
        }
        @include mobile {
        }
      }
      &_mess {
        width: 24px;
        border-radius: 50%;
        @include center;

        background-color: #2e2e32;
        height: 24px;
        img {
          width: 12px !important;
          height: 12px !important;
        }
      }
      &_location {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: var(--card-main);
        span {
          color: $--sec-text;
        }
      }
      &_stats {
        justify-self: flex-end;
        width: max-content;
        margin-left: auto;
        padding: 0px 8px;
        display: flex;
        align-items: center;
        border-radius: 20px;
        // background-color: rgba(85, 213, 167, 0.1);
        color: var(--stock-green);
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
      }
    }
  }
  &_bot {
    width: 100%;
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      width: 150px;
      height: 34px;
      @include mobile {
        width: 125px;
        height: 28px;
      }
    }
    span {
      span {
        font-size: 18px;
      }
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: var(--card-main);
      @include mobile {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
      }
    }
  }
}
